import { DatePicker, Form, Select } from 'antd';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTutorClasses } from "app-redux/slices/tutorClass"
import { addAttendDetail } from "app-redux/slices/attendDetail"
import { getMembers } from "app-redux/slices/member"
import { useSelector } from "react-redux";
import { showSuccessMessage, showErrorMessage, formatDate } from "helper/Helper"

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

// eslint-disable-next-line react/prop-types
const AddAttendDetail = ({ form, setIsModalOpen }) => {
  const { member, tutorClass } = useSelector(state => state)
  const [classList, setClassList] = useState([]);
  const [memberList, setMemberList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTutorClasses());

  }, [])

  useEffect(() => {
    const tempClassList = tutorClass.tutorClasses.map((item, index) => {
      return {
        value: index,
        label: item.name
      }
    })
    setClassList(tempClassList)
  }, [tutorClass])

  useEffect(() => {
    const tempList = member.members.map((item, index) => {
      return {
        value: index,
        label: item.name
      }
    })
    setMemberList(tempList)
  }, [member])

  const onFinish = (values) => {
    const temp = new Date(values.attendDate)
    console.log(temp)

    const payload = {
      classId: tutorClass.tutorClasses[values.tutorClass].id,
      type: Number(values.type),
      attendDate: formatDate(temp),
      memberId: member.members[values.member].id,
      note: ''
    }

    console.log({ payload })

    dispatch(addAttendDetail(payload)).unwrap().then((_) => {
      showSuccessMessage("Thêm thành công")
    })
      .catch((e) => {
        showErrorMessage(e.message)
      })

    setIsModalOpen(false)
  }
  const onChangeSelect = (value) => {
    console.log(value)
    const classId = tutorClass.tutorClasses[value].id
    const params = `classId=${classId}`
    dispatch(getMembers(params));
  }

  return (
    <>
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item name="tutorClass" label="Chọn lớp" rules={[{ required: true }]}>
          <Select
            showSearch
            style={{ width: 200 }}
            onChange={onChangeSelect}
            placeholder="Lọc theo lớp"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toUpperCase().includes(input.toLocaleUpperCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={classList}
          />

        </Form.Item>
        <Form.Item name="member" label="Chọn học sinh" rules={[{ required: true }]}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Tên học sinh"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toUpperCase().includes(input.toLocaleUpperCase())}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={memberList}
          />
        </Form.Item>
        <Form.Item name="attendDate" label="Ngày học" rules={[{ required: true }]}>
          <DatePicker />
        </Form.Item>
        <Form.Item name="type" label="Trạng thái" rules={[{ required: true }]}>
          <Select
            defaultValue="Default"
            style={{ width: 120 }}
            options={[
              {
                value: '0',
                label: 'Default',
              },
              {
                value: '2',
                label: 'Học bù',
              },
              {
                value: '4',
                label: 'Học thêm',
              }
            ]}
          />
        </Form.Item>
      </Form>
    </>
  )
}

export default AddAttendDetail
